import React, { Component } from 'react';
import "../css/Login.css";
import Swal from 'sweetalert2'
import api from '../api';


class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };

    }

    login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        console.log(data.get('user'), data.get('password'))

        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            object[key] = value;
        });



        api.post('/login', object)
            .then(function (response) {
                console.log(response.data[0])
                let { PK_USU, NOME, FK_MED } = response.data[0];
                console.log(PK_USU)
                if (PK_USU > 0 && PK_USU > 0) {
                    localStorage['authClinigastro'] = JSON.stringify({ authenticated: true, user: PK_USU, name: NOME, fk_med: FK_MED })
                    window.location.href = '/clinigastro/agenda'
                } else {
                    Swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
                }
            })
            .catch(function (error) {
                Swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
            });
    }


    componentDidMount() {
        let strAuth = localStorage['authClinigastro'] || JSON.stringify({ authenticated: false, user: 0 })
        let auth = JSON.parse(strAuth)
        if (auth.authenticated) window.location.href = '/clinigastro/agenda'
    }

    render() {
        return (
            <div className="app">

                <div className="containerLogin">

                    <div className="rowLogin loginImageDiv">
                        <img alt="Clinigastro" src={require('../imgs/logo.png')} title="Clinigastro" className="loginImage" />
                    </div>

                    <div className="rowLogin loginInputs">
                        <form className="form-signin" id="loginForm" onSubmit={this.login}>
                            <div className="form-label-group">
                                <input type="text" id="user" name="user" className="loginInput" placeholder="Usuário" />
                                <label htmlFor="user">Usuário</label>
                            </div>

                            <div className="form-label-group">
                                <input type="password" id="password" name="password" className="loginInput" placeholder="Senha" />
                                <label htmlFor="password">Senha</label>
                            </div>

                            <button className="loginButton" type="submit" rel="nofollow">
                                <span><p className='loginButtonText'>Entrar</p></span>
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        );
    }
}

export default Login;
