import axios from 'axios';

// const backendUrl = 'http://localhost:21001'
// const backendUrl = 'https://83.136.219.177:21001'
const backendUrl = 'https://apps.delphus.inf.br:21001'

const api = axios.create({
    baseURL: backendUrl + `/api/`
});

api.backendUrl = backendUrl

let auth = JSON.parse(localStorage['authClinigastro'] || JSON.stringify({ authenticated: false, user: 0 }));

api.getComboData = async (table) => {
    return new Promise(async (resolve, reject) => {
        await api.get('/' + table + '/combo?pk_usu=' + auth.user).then(async r => {
            console.log(r.data)
            resolve(r.data)
        })
    })
}


export default api