import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),

    }
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#ffffff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '2px solid #264153',

    fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '17px',
    paddingLeft: '10px',
    paddingTop: '6px',
    paddingBottom: '6px',

    '&:focus': {
      borderRadius: 5,
      borderColor: '#264153',
      borderWidth: '2px',
      backgroundColor: '#ffffff',
      height: '17px'

    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '6px',
    marginTop: '6px'

  },
  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    backgroundColor: '#264153',
    color: 'white',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink': {
      padding: '2px',
      top: '2px',
      color: '#264153'
    },
  },

}));

export default function CustomizedSelects(props) {
  const classes = useStyles();

  return (
    <>
      <InputLabel htmlFor="demo-customized-select-native" className={classes.label}>{props.label}</InputLabel>

      <FormControl className={classes.margin}>
        <NativeSelect
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput />}
          {...props}
        >
          <option aria-label="Nenhum" value="" />
          {
            props.data.map((item) => {
              return (<option value={item.value}>{item.display}</option>)
            })
          }

        </NativeSelect>
      </FormControl>
    </>
  );
}