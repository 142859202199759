import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import TableAgenda from '../components/TableAgenda'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import DatePicker from 'react-date-picker'
import { Icon } from 'react-icons-kit';
import { androidArrowDropright } from 'react-icons-kit/ionicons/androidArrowDropright'
import { androidArrowDropleft } from 'react-icons-kit/ionicons/androidArrowDropleft'
import withReactContent from 'sweetalert2-react-content'
import { dateSql } from '../Utils';
import { ic_exit_to_app } from 'react-icons-kit/md/ic_exit_to_app'

import api from '../api'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';


function Agenda() {
  const [data, setData] = useState([])
  const [dataAgenda, setDataAgenda] = useState(new Date())
  const swal = withReactContent(Swal)
  const [comboMed, setComboMed] = useState([{ value: '', display: '' }])

  let auth = JSON.parse(localStorage['authClinigastro']);
  var moment = require('moment');

  const [fk_med, setFk_Med] = useState(auth.fk_med);


  const columns = [
    { id: 'STATUS', numeric: false, label: 'Nome', align: 'left', minWidth: 200 },
    { id: 'HORARIO', numeric: false, label: 'Login', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Buscando agenda..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboMed(await api.getComboData('medicos'))

      swal.close()
      getData()
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      getData()
    }

    fetchData()
  }, [dataAgenda])

  useEffect(() => {
    const fetchData = async () => {
      getData()
    }

    fetchData()
  }, [fk_med])


  function setDate(e, inc) {
    e.preventDefault()
    let data = moment([dataAgenda.getFullYear(), dataAgenda.getMonth(), dataAgenda.getDate()]).add(inc, 'days');
    if (inc == 0)
      data = new Date()
    setDataAgenda(new Date(data))

    // getData(e)
  }


  function getData(e) {
    swal.fire({
      html: <FrLoading text="Buscando agenda..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    console.log(fk_med)
    api.get('/buscaDados?fk_med=' + fk_med + '&data=' + dateSql(dataAgenda.toISOString())).then(r => {
      setData(r.data)
      swal.close()
    })
  }

  const handleChange = (e) => {
    console.log(e.target.value)
    setFk_Med(e.target.value)
    // getData()

  };

  const handleChangeData = (date) => {
    setDataAgenda(date)
    // getData()

  };


  function logout(e) {
    e.preventDefault();
    swal.fire({
      title: 'Deseja sair do sistema?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        localStorage['authClinigastro'] = JSON.stringify({ authenticated: false, user: 0, fk_med: 0 })
        window.location.href = '/clinigastro/login'
      }
    })

  }


  return (
    <div className="app">

      <div className="containerList">

        <div className="headerDiv">
          <img alt="Clinigastro" src={require('../imgs/logo.png')} title="Clinigastro" className="menuImage" />
        </div>

        <div className="itemsDiv">
          <p className="listGroupTitle">

            {/* Bem vindo {auth.name} */}
            <button className="backButton" onClick={logout}>
              <Icon icon={ic_exit_to_app} size={22} />
            </button>

            <FrSelect
              value={fk_med}
              onChange={handleChange}
              name="fk_med"
              id="fk_med"
              label='Agenda:'
              data={comboMed}
              style={{ width: 250 }}
            />

          </p>
          <div className="nav">
            <div className="dayNav">
              <DatePicker
                name="data_agenda"
                id="data_agenda"
                onChange={handleChangeData} value={dataAgenda} className="datePicker" showLeadingZeros clearIcon={""} />
              <div className="navButtons">
                <button className="navButton" onClick={(e) => setDate(e, -1)}> <Icon size={20} icon={androidArrowDropleft}></Icon> </button>
                <button className="navButton" onClick={(e) => setDate(e, 0)}> Hoje </button>
                <button className="navButton" onClick={(e) => setDate(e, 1)}> <Icon size={20} icon={androidArrowDropright}></Icon> </button>
              </div>
            </div>
          </div>
          <div className="tableDiv">
            <TableAgenda data={data} />

          </div>

        </div>
      </div>
    </div>
  );
}

export default Agenda;
