import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Swal from 'sweetalert2'
import './index.css';



const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);


const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#264153'

    },
    "& .Mui-selected:hover": {
      backgroundColor: '#264153'

    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#c0c0c0',
    color: theme.palette.common.black,
    // border: 50,
    // borderRadius: 10,
    borderColor: '#264153',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TableAgenda(props) {
  const classes = useStyles();

  const [data, setData] = useState(props.data)


  useEffect(() => {
    setData(props.data)
  }, [props.data])


  const handleClick = (event, name) => {
    console.log(name)

  };


  return (
    <TableContainer component={Paper}>
      {/* <Table stickyHeader aria-label="sticky table"> */}
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          {
            data.map((row) => {

              return (
                <>
                  <StyledTableRow>
                    <StyledTableCell style={{ minWidth: 10, width: 10, backgroundColor: row['COR'] }}>
                      <p style={{ marginBottom: '2px' }}><strong>{row['HORARIO']}</strong></p>
                      <p style={{ marginBottom: '2px' }}><strong>{row['STATUS']}</strong></p>

                    </StyledTableCell>

                    <TableCell style={{
                      minWidth: 30, width: 30, backgroundColor: row['COR'],
                    }}>

                      <p style={{ marginBottom: '2px' }}><strong>{row['NOME']}</strong></p>
                      <p style={{ marginBottom: '2px' }}>Exame: {row['EXAME']}</p>
                      <p style={{ marginBottom: '2px' }}>Convênio: {row['CONVENIO']}</p>

                    </TableCell>




                  </StyledTableRow>
                </>
              );

            })
          }

        </TableBody>

      </Table>
    </TableContainer >
  );
}
