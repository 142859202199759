import React from 'react';
import ReactLoading from 'react-loading';

import './index.css';

function FrLoading(props) {
    return (
        <div className="FrLoading">
            {props.text}
            <ReactLoading type="bars" color="#264153"/>
        </div>
    );
}

export default FrLoading;